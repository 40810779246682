[class*="iframe-block"] .skeleton {
    display: flex;
    align-items: center;
}

[class*="iframe-block"] .skeleton .frame:after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to right, rgb(158 158 158) 0%, #d0d0d0 40%, #bababa 70%, rgb(158 158 158) 100%);
    transform: translateX(-100%);
    animation: shimmer 1.5s ease-in infinite;
}

[class*="iframe-block"] .skeleton div .frame:nth-child(2):before {
    animation-delay: 0.2s;
}

[class*="iframe-block"] .skeleton .frame:before {
    animation-delay: 0.3s;
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}