.account-content h2 {
    font-size: 1.875rem;
}

.account-navigation a {
    color: var(--text-colour);
    padding: 4px 8px;
    border-radius: var(--container-roundness)
}

.account-navigation a.active {
    opacity: 1;
    font-weight: 501;
}

.account-navigation a:hover {
    background: rgba(0,0,0,0.05);
}

.account-navigation a {
    opacity: 0.8;
}