.preview .product-search-block [type='text'], .preview .product-search-block [type='email'], .preview .product-search-block [type='url'], .preview .product-search-block [type='password'], .preview .product-search-block [type='number'],
.preview .product-search-block [type='date'], .preview .product-search-block [type='datetime-local'], .preview .product-search-block [type='month'], .preview .product-search-block [type='search'], .preview .product-search-block [type='tel']:not(.PhoneInputInput),
.preview .product-search-block [type='time'], .preview .product-search-block [type='week'], .preview .product-search-block textarea, .preview .product-search-block select, .preview .product-search-block .PhoneInput, .preview .product-search-block [id^="headlessui-combobox-button-"] {
    @apply h-11;
    border-radius: 0 !important;
    border: none !important;
    color: var(--text-colour) !important;
}

.preview .section-white .product-search-block [type='text'], .preview .section-white .product-search-block [type='email'], .preview .section-white .product-search-block [type='url'], .preview .section-white .product-search-block [type='password'], .preview .section-white .product-search-block [type='number'],
.preview .section-white .product-search-block [type='date'], .preview .section-white .product-search-block [type='datetime-local'], .preview .section-white .product-search-block [type='month'], .preview .section-white .product-search-block [type='search'], .preview .section-white .product-search-block [type='tel']:not(.PhoneInputInput),
.preview .section-white .product-search-block [type='time'], .preview .section-white .product-search-block [type='week'], .preview .section-white .product-search-block textarea, .preview .section-white .product-search-block select, .preview .section-white .product-search-block .PhoneInput, .preview .section-white .product-search-block [id^="headlessui-combobox-button-"] {
    background-color: var(--light-background) !important;
}

@media (max-width: theme('screens.sm')) {
    .preview .product-search-block .search-fields > div:first-child select,
    .preview .product-search-block .search-fields > div:first-child input,
    .preview .product-search-block .search-fields > div:first-child [id^="headlessui-combobox-button-"] {
        border-top-left-radius: var(--container-roundness) !important;
        border-top-right-radius: var(--container-roundness) !important;
    }
}

@media (min-width: theme('screens.sm')) {
    .preview .product-search-block .search-fields > div:first-child select,
    .preview .product-search-block .search-fields > div:first-child input,
    .preview .product-search-block .search-fields > div:first-child [id^="headlessui-combobox-button-"] {
        border-top-left-radius: var(--container-roundness) !important;
        border-bottom-left-radius: var(--container-roundness) !important;
    }
}

@media (max-width: theme('screens.sm')) {
    .preview .product-search-block button {
        @apply h-11 !mt-[2px] w-full;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: var(--button-roundness) !important;
        border-bottom-left-radius: var(--button-roundness) !important;
    }
}

@media (min-width: theme('screens.sm')) {
    .preview .product-search-block button {
        @apply h-11 ml-[1px];
        border-top-left-radius: 0;
        border-top-right-radius: var(--button-roundness) !important;
        border-bottom-right-radius: var(--button-roundness) !important;
        border-bottom-left-radius: 0;
    }
}

.preview .product-search-block div > div {
    flex: 1;
}