.navigation-block-checkout.section-dark a, .navigation-block-checkout.section-black a {
    color: var(--light-text-colour);
}

.navigation-block-checkout.section-brand a {
    color: var(--primary-button-text-colour);
}

.navigation-block-checkout.section-white a, .navigation-block-checkout.section-light a, .navigation-block-checkout.section-grey a {
    color: var(--text-colour);
}

.shimmering-background {
    position: relative;
    overflow: hidden;
}

.shimmering-background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.1) 35%,
            rgba(255, 255, 255, 0.35) 50%,
            rgba(255, 255, 255, 0.1) 65%,
            rgba(255, 255, 255, 0) 100%
    );
    background-size: 600%;
    animation: shimmer-checkout 6s ease-in-out infinite;
    z-index: 0;
}

@keyframes shimmer-checkout {
    20% {
        background-position: 100% 50%; 
    }
    80% {
        background-position: 0 50%;
    }
}