[class*="list-of-items-block"] li h6, [class*="list-of-items-block"] li h5 {
    margin-bottom: 0;
}

.preview .list-of-items-block-2 a {
    color: white !important;
}

.preview .list-of-items-block-2 a:not(.action-link):not(.button) {
    text-decoration: underline;
}