[class*="navigation-block-"] .section-dark a:not(.button), [class*="navigation-block-"] .section-black a:not(.button) {
    color: var(--light-text-colour);
}

[class*="navigation-block-"] .section-brand a:not(.button) {
    color: var(--primary-button-text-colour);
}

[class*="navigation-block-"] .section-white a:not(.button), [class*="navigation-block-"] .section-light a:not(.button), [class*="navigation-block-"] .section-grey a:not(.button) {
    color: var(--text-colour);
}

[class*="navigation-block-"] nav.section-white [id*="headlessui-popover-button-"], [class*="navigation-block-"] nav.section-light [id*="headlessui-popover-button-"], [class*="navigation-block-"] nav.section-grey [id*="headlessui-popover-button-"] {
    color: var(--text-colour);
}

[class*="navigation-block-"] nav.section-dark [id*="headlessui-popover-button-"], [class*="navigation-block-"] nav.section-black [id*="headlessui-popover-button-"] {
    color: var(--light-text-colour);
}

[class*="navigation-block-"] nav.section-brand [id*="headlessui-popover-button-"] {
    color: var(--primary-button-text-colour);
}


[class*="navigation-block-"] nav.block ul {
    max-height: calc(100vh - env(safe-area-inset-bottom) - 184px);
}

[class*="navigation-block-"] .fit-text {
    margin: 0 !important;
}

[class*="navigation-block-"].section-white .fit-text, [class*="navigation-block-"].section-light .fit-text, [class*="navigation-block-"].section-grey .fit-text {
    color: var(--text-colour) !important;
}

[class*="navigation-block-"].section-brand .fit-text {
    color: var(--primary-button-text-colour) !important;
}

[class*="navigation-block-"].section-dark .fit-text, [class*="navigation-block-"].section-black .fit-text {
    color: var(--light-text-colour) !important;
}